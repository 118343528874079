<template>
	<div class="position-relative">
         <section class="blockElement">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-12">
                     <h1 class="mb-4">{{contentlist.terms_of_use_apec_content1}}</h1>
                  </div>
               </div>
               <div class="row privacyPolicy">
                  <div class="col-12">
                     <p>{{contentlist.terms_of_use_apec_content2}} <strong>{{contentlist.terms_of_use_apec_content3}}</strong> {{contentlist.terms_of_use_apec_content4}}</p>

                     <p>{{contentlist.terms_of_use_apec_content5}}<strong>{{contentlist.terms_of_use_apec_content6}}</strong> {{contentlist.terms_of_use_apec_content7}} <strong>{{contentlist.terms_of_use_apec_content8}}</strong>{{contentlist.terms_of_use_apec_content9}}</p>

                     <p>{{contentlist.terms_of_use_apec_content10}}</p>

                     <p>{{contentlist.terms_of_use_apec_content11}}</p>

                     <p>{{contentlist.terms_of_use_apec_content12}}</p>

                     <p>{{contentlist.terms_of_use_apec_content13}}</p>

                     <p>{{contentlist.terms_of_use_apec_content14}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content15}}</strong></p>

                     <ul>
                        <li><strong>{{contentlist.terms_of_use_apec_content16}}</strong> {{contentlist.terms_of_use_apec_content17}}</li>
                        <li><strong>{{contentlist.terms_of_use_apec_content18}}</strong> {{contentlist.terms_of_use_apec_content19}}</li>
                        <li><strong>{{contentlist.terms_of_use_apec_content20}}</strong> {{contentlist.terms_of_use_apec_content21}}</li>
                        
                     </ul>

                     <p>{{contentlist.terms_of_use_apec_content22}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content23}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content24}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content25}}</strong> {{contentlist.terms_of_use_apec_content26}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content27}}</strong> {{contentlist.terms_of_use_apec_content28}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content29}}</strong> {{contentlist.terms_of_use_apec_content30}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content31}}</strong> {{contentlist.terms_of_use_apec_content32}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content33}}</strong> {{contentlist.terms_of_use_apec_content34}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content35}}</strong> {{contentlist.terms_of_use_apec_content36}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content37}}</strong> {{contentlist.terms_of_use_apec_content38}} <strong>{{contentlist.terms_of_use_apec_content39}}</strong> {{contentlist.terms_of_use_apec_content40}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content41}}</strong> {{contentlist.terms_of_use_apec_content42}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content43}}</strong> {{contentlist.terms_of_use_apec_content44}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content45}}</strong> {{contentlist.terms_of_use_apec_content46}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content47}}</strong> {{contentlist.terms_of_use_apec_content48}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content49}}</strong> {{contentlist.terms_of_use_apec_content50}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content51}}</strong> {{contentlist.terms_of_use_apec_content52}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content53}}</strong> {{contentlist.terms_of_use_apec_content54}} <strong>{{contentlist.terms_of_use_apec_content55}}</strong> {{contentlist.terms_of_use_apec_content56}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content57}}</strong> {{contentlist.terms_of_use_apec_content58}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content59}}</strong> {{contentlist.terms_of_use_apec_content60}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content61}}</li>
                        <li>{{contentlist.terms_of_use_apec_content62}}</li>
                        <li>{{contentlist.terms_of_use_apec_content63}}</li>
                        <li>{{contentlist.terms_of_use_apec_content64}}</li>
                        
                        
                     </ul>

                     <p><strong>{{contentlist.terms_of_use_apec_content65}}</strong> {{contentlist.terms_of_use_apec_content66}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content67}}</strong> {{contentlist.terms_of_use_apec_content68}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content69}}</strong> {{contentlist.terms_of_use_apec_content70}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content71}}</strong> {{contentlist.terms_of_use_apec_content72}} <router-link to="/" class="secondarycolor">{{contentlist.terms_of_use_apec_content72_link}}</router-link> {{contentlist.terms_of_use_apec_content72_a}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content73}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content74}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content75}}</li>
                        <li>{{contentlist.terms_of_use_apec_content76}}
                           
                           <ul>
                              <li>{{contentlist.terms_of_use_apec_content77}}</li>
                              <li>{{contentlist.terms_of_use_apec_content78}}</li>
                              <li>{{contentlist.terms_of_use_apec_content79}}</li>
                              <li>{{contentlist.terms_of_use_apec_content80}}</li>
                              <li>{{contentlist.terms_of_use_apec_content81}}</li>
                              <li>{{contentlist.terms_of_use_apec_content82}}</li>
                           </ul>
                        
                        </li>
                        <li>{{contentlist.terms_of_use_apec_content83}}</li>
                        <li>{{contentlist.terms_of_use_apec_content84}}</li>
                        <li>{{contentlist.terms_of_use_apec_content85}}</li>
                        <li>{{contentlist.terms_of_use_apec_content86}}</li>
                        <li>{{contentlist.terms_of_use_apec_content87}}</li>
                        <li>{{contentlist.terms_of_use_apec_content88}}</li>
                        <li>{{contentlist.terms_of_use_apec_content89}}</li>
                     </ul>

                     <p><strong>{{contentlist.terms_of_use_apec_content90}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content91}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content92}}</li>
                        <li>{{contentlist.terms_of_use_apec_content93}}</li>
                        <li>{{contentlist.terms_of_use_apec_content94}}</li>
                     </ul>

                     <p><strong>{{contentlist.terms_of_use_apec_content95}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content96}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content97}}</li>
                        <li>{{contentlist.terms_of_use_apec_content98}}</li>
                     </ul>

                     <p><strong>{{contentlist.terms_of_use_apec_content99}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content100}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content101}}</li>
                        <li>{{contentlist.terms_of_use_apec_content102}}</li>
                        <li>{{contentlist.terms_of_use_apec_content103}}</li>
                        <li>{{contentlist.terms_of_use_apec_content104}}</li>
                        <li>{{contentlist.terms_of_use_apec_content105}}</li>
                        <li>{{contentlist.terms_of_use_apec_content106}}</li>
                        <li>{{contentlist.terms_of_use_apec_content107}}</li>
                        <li>{{contentlist.terms_of_use_apec_content108}}</li>
                        
                     </ul>

                     <p>{{contentlist.terms_of_use_apec_content109}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content110}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content111}}</p>

                     <p>{{contentlist.terms_of_use_apec_content112}}</p>

                     <p>{{contentlist.terms_of_use_apec_content113}}</p>

                     <p>{{contentlist.terms_of_use_apec_content114}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content115}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content116}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content117}}</strong> {{contentlist.terms_of_use_apec_content118}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content119}}</strong> {{contentlist.terms_of_use_apec_content120}}</p>

                     <p>{{contentlist.terms_of_use_apec_content121}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content122}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content123}}</p>

                     <p>{{contentlist.terms_of_use_apec_content124}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content125}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content126}}</p>

                     <p>{{contentlist.terms_of_use_apec_content127}}</p>

                     <p>{{contentlist.terms_of_use_apec_content128}}</p>

                     <p>{{contentlist.terms_of_use_apec_content129}}</p>

                     <p>{{contentlist.terms_of_use_apec_content130}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content131}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content132}}</p>

                     <p>{{contentlist.terms_of_use_apec_content133}}</p>

                     <ul>
                        <li><strong>{{contentlist.terms_of_use_apec_content134}}</strong> {{contentlist.terms_of_use_apec_content35}}</li>
                        <li><strong>{{contentlist.terms_of_use_apec_content136}}</strong> {{contentlist.terms_of_use_apec_content37}}</li>
                        <li><strong>{{contentlist.terms_of_use_apec_content138}}</strong> {{contentlist.terms_of_use_apec_content39}}</li>                        
                     </ul>

                     <p><strong>{{contentlist.security_content}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content141}}</p>

                     <p>{{contentlist.terms_of_use_apec_content142}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content143}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content144}}</p>

                     <p>{{contentlist.terms_of_use_apec_content145}}<strong>{{contentlist.terms_of_use_apec_content146}}</strong>{{contentlist.terms_of_use_apec_content147}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content148}}</li>
                        <li>{{contentlist.terms_of_use_apec_content149}}</li>
                        <li>{{contentlist.terms_of_use_apec_content150}}</li>
                        <li>{{contentlist.terms_of_use_apec_content151}}</li>
                        <li>{{contentlist.terms_of_use_apec_content152}}</li>                        
                     </ul>

                     <p>{{contentlist.terms_of_use_apec_content153}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content154}}</li>
                        <li>{{contentlist.terms_of_use_apec_content155}}</li>                      
                     </ul>

                     <p><strong>{{contentlist.terms_of_use_apec_content156}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content157}} <strong>{{contentlist.terms_of_use_apec_content158}}</strong> {{contentlist.terms_of_use_apec_content159}}</p>

                     <p>{{contentlist.terms_of_use_apec_content160}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content161}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content162}}</p>
                     <p>{{contentlist.terms_of_use_apec_content163}}</p>
                     <p>{{contentlist.terms_of_use_apec_content164}}</p>
                     <p>{{contentlist.terms_of_use_apec_content165}}</p>
                     <p>{{contentlist.terms_of_use_apec_content166}}</p>
                     <p>{{contentlist.terms_of_use_apec_content167}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content168}}</li>
                        <li>{{contentlist.terms_of_use_apec_content169}}</li>
                        <li>{{contentlist.terms_of_use_apec_content170}}</li>
                        <li>{{contentlist.terms_of_use_apec_content171}}</li>
                                               
                     </ul>

                     <p><strong>{{contentlist.terms_of_use_apec_content172}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content173}}</p>
                     <p>{{contentlist.terms_of_use_apec_content174}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content175}}</li>
                        <li>{{contentlist.terms_of_use_apec_content176}}</li>
                        <li>{{contentlist.terms_of_use_apec_content177}}</li>
                        <li>{{contentlist.terms_of_use_apec_content178}}</li>
                        <li>{{contentlist.terms_of_use_apec_content179}}</li>
                        <li>{{contentlist.terms_of_use_apec_content180}}</li>
                                               
                     </ul>

                     <p>{{contentlist.terms_of_use_apec_content181}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content182}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content183}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content184}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content185}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content186}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content187}}</p>

                     <p>{{contentlist.terms_of_use_apec_content188}}</p>

                     <p>{{contentlist.terms_of_use_apec_content189}} <a href="mailto:support@capitalwallet.com" class="secondarycolor">{{contentlist.support_email_content}}</a></p>

                     <p><strong>{{contentlist.terms_of_use_apec_content191}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content192}}<strong>{{contentlist.terms_of_use_apec_content193}}</strong>{{contentlist.terms_of_use_apec_content194}}</p>

                     <p>{{contentlist.terms_of_use_apec_content195}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content196}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content197}}</p>

                     <p>{{contentlist.terms_of_use_apec_content198}}</p>

                     <p>{{contentlist.terms_of_use_apec_content199}}</p>

                     <ul>
                        <li>{{contentlist.terms_of_use_apec_content200}}</li>
                        <li>{{contentlist.terms_of_use_apec_content201}}</li>
                        <li>{{contentlist.terms_of_use_apec_content202}}</li>
                        <li>{{contentlist.terms_of_use_apec_content203}}</li>
                        <li>{{contentlist.terms_of_use_apec_content204}}</li>
                        <li>{{contentlist.terms_of_use_apec_content205}}</li>
                        <li>{{contentlist.terms_of_use_apec_content206}}</li>
                        <li>{{contentlist.terms_of_use_apec_content207}}</li>
                        <li>{{contentlist.terms_of_use_apec_content208}}</li>
                        <li>{{contentlist.terms_of_use_apec_content209}}</li>
                        
                                               
                     </ul>

                     <p>{{contentlist.terms_of_use_apec_content210}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content211}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content212}}</p>

                     <p>{{contentlist.terms_of_use_apec_content213}}</p>

                     <p>{{contentlist.terms_of_use_apec_content214}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content215}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content216}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content217}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content218}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content219}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content220}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content221}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content222}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content223}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content224}}</p>

                     <p><strong>{{contentlist.terms_of_use_apec_content225}}</strong></p>

                     <p>{{contentlist.terms_of_use_apec_content226}}</p>

                     <p>{{contentlist.terms_of_use_apec_content227}}</p>

                     <p>{{contentlist.terms_of_use_apec_content228}}</p>

                        
                  </div>
               </div>
               
               
            </div>
         </section>
         
     </div>
</template>
<script>
  
  import {
    commonAllmixins
  } from '@/plugins/commonAll' //anamica
  export default ({
    mixins: [commonAllmixins], //anamica
    data() {
      return {
        tab: 1,
      };
    },
   
    created() {
      // console.log(this.contentlist)
    }
  });
</script>