<template>
	<div class="position-relative">
         <section class="blockElement">
            <div class="container">
               <div class="row justify-content-center">
                  <div class="col-12">
                     <h1 class="mb-4">{{contentlist.terms_of_use_global_content1}}</h1>
                  </div>
               </div>
               <div class="row privacyPolicy">
                  <div class="col-12">
                     <p>{{contentlist.terms_of_use_global_content2}} <router-link :to="entityPath+'/'" class="secondarycolor">{{contentlist.capital_wallet_content}}</router-link> {{contentlist.terms_of_use_global_content4}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content5}}</router-link>{{contentlist.terms_of_use_global_content6}}</p>
                     <p>{{contentlist.terms_of_use_global_content7}}</p>
                     <p>{{contentlist.terms_of_use_global_content8}}</p>
                     <p>{{contentlist.terms_of_use_global_content9}} <router-link :to="entityPath+'/'" class="secondarycolor">{{contentlist.terms_of_use_global_content10}}</router-link></p>
                        <h5>{{contentlist.terms_of_use_global_content11}}</h5>
                        <ul>
                           <li>{{contentlist.terms_of_use_global_content12}} <router-link :to="entityPath+'/'" class="secondarycolor">{{contentlist.terms_of_use_global_content12_link}}</router-link>{{contentlist.terms_of_use_global_content13}}</li>
                           <li>{{contentlist.terms_of_use_global_content14}}</li>
                           <li>{{contentlist.terms_of_use_global_content15}}</li>
                           <li>{{contentlist.terms_of_use_global_content16}}</li>
                           <li>{{contentlist.terms_of_use_global_content17}} <router-link :to="entityPath+'/help-centre'" class="secondarycolor">{{contentlist.help_centre_content}}</router-link> {{contentlist.terms_of_use_global_content19}}</li>
                           <li>{{contentlist.terms_of_use_global_content20}}</li>
                           <li>{{contentlist.terms_of_use_global_content21}}</li>
                           <li>{{contentlist.terms_of_use_global_content22}}</li>
                           <li>{{contentlist.terms_of_use_global_content23}}</li>
                           <li>{{contentlist.terms_of_use_global_content24}}</li>
                           <li>{{contentlist.terms_of_use_global_content25}}</li>
                           <li>{{contentlist.terms_of_use_global_content26}} <router-link :to="entityPath+'/aml-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content27}}</router-link> {{contentlist.terms_of_use_global_content28}}</li>
                           <li>{{contentlist.terms_of_use_global_content29}}</li>
                        </ul>
                        <h5>{{contentlist.terms_of_use_global_content30}}</h5>
                        <p><strong>{{contentlist.terms_of_use_global_content31}}</strong></p>
                        <p><strong>{{contentlist.terms_of_use_global_content32}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content33}}</p>
                        <p><strong>{{contentlist.terms_of_use_global_content34}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content35}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content36}}</router-link> {{contentlist.terms_of_use_global_content37}}</p>
                        <p><strong>{{contentlist.terms_of_use_global_content38}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content39}}</p>
                        <p><strong>{{contentlist.terms_of_use_global_content40}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content41}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content42}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content43}} <router-link :to="entityPath+'/merchant-solution'" class="secondarycolor">{{contentlist.terms_of_use_global_content44}}</router-link>{{contentlist.terms_of_use_global_content45}}</p>
                        <p>{{contentlist.terms_of_use_global_content46}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content47}}</strong></p>
                        <p><strong>{{contentlist.terms_of_use_global_content48}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content49}}<a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="secondarycolor">{{contentlist.terms_of_use_global_content50}}</a>{{contentlist.terms_of_use_global_content51}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content52}}</router-link> {{contentlist.terms_of_use_global_content53}} <router-link :to="entityPath+'/cookies-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content54}}</router-link>{{contentlist.terms_of_use_global_content55}}</p>
                        <p><strong>{{contentlist.terms_of_use_global_content56}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content57}}</p>

                        <p>{{contentlist.terms_of_use_global_content58}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content59}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content60}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content61}}</router-link> {{contentlist.terms_of_use_global_content62}} <router-link :to="entityPath+'/cookies-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content63}}</router-link>{{contentlist.terms_of_use_global_content64}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content65}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content66}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content67}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content68}}</p>

                        <p>{{contentlist.terms_of_use_global_content69}}</p>

                        <p>{{contentlist.terms_of_use_global_content70}}</p>

                        <ul>
                           <li>
                              {{contentlist.terms_of_use_global_content71}}
                           </li>
                           <li>
                              {{contentlist.terms_of_use_global_content72}}
                           </li>
                           <li>
                              {{contentlist.terms_of_use_global_content73}}
                           </li>
                        </ul>

                        <h5>{{contentlist.terms_of_use_global_content74}}</h5>

                        <p>{{contentlist.terms_of_use_global_content75}}</p>

                        <ul>
                           <li>
                              {{contentlist.terms_of_use_global_content76}}
                           </li>
                           <li>{{contentlist.terms_of_use_global_content77}}</li>

                        </ul>
                        <p>{{contentlist.terms_of_use_global_content78}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content79}}</strong></p>
                        <p><strong>{{contentlist.terms_of_use_global_content80}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content81}}</p>

                        <p>{{contentlist.terms_of_use_global_content82}}</p>

                        <p>{{contentlist.terms_of_use_global_content83}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content84}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content85}}</p>

                        <ul>
                           <li>{{contentlist.terms_of_use_global_content86}}</li>
                           <li>{{contentlist.terms_of_use_global_content87}}</li>
                           <li>{{contentlist.terms_of_use_global_content88}}</li>
                           <li>{{contentlist.terms_of_use_global_content89}}</li>
                           <li>{{contentlist.terms_of_use_global_content90}}</li>
                        </ul>

                        <p>{{contentlist.terms_of_use_global_content91}}</p>


                        <ul>
                           <li>{{contentlist.terms_of_use_global_content92}}</li>
                           <li>{{contentlist.terms_of_use_global_content93}}</li>
                           <li>{{contentlist.terms_of_use_global_content94}}</li>
                           <li>{{contentlist.terms_of_use_global_content95}}</li>
                        </ul>

                        <p><strong>{{contentlist.terms_of_use_global_content96}}</strong></p>
                        <p>{{contentlist.terms_of_use_global_content97}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content98}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content99}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content100}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content101}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content102}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content103}} <router-link :to="entityPath+'/pricing'" class="secondarycolor">{{contentlist.terms_of_use_global_content104}}</router-link>{{contentlist.terms_of_use_global_content105}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content106}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content107}}</p>

                        <ul>
                           <li>{{contentlist.terms_of_use_global_content108}}</li>
                           <li>{{contentlist.terms_of_use_global_content109}}</li>
                        </ul>

                        <p><strong>{{contentlist.terms_of_use_global_content110}}</strong></p>

                        <ul>
                           <li>{{contentlist.terms_of_use_global_content111}} <router-link :to="entityPath+'/crypto-conversion'" class="secondarycolor">{{contentlist.terms_of_use_global_content112}}</router-link>{{contentlist.terms_of_use_global_content113}}</li>

                           <li>{{contentlist.terms_of_use_global_content114}}</li>

                           <li>{{contentlist.terms_of_use_global_content115}}</li>

                           <li>{{contentlist.terms_of_use_global_content116}}</li>

                           <li>{{contentlist.terms_of_use_global_content117}}</li>

                           <li>{{contentlist.terms_of_use_global_content118}}</li>

                           <li>{{contentlist.terms_of_use_global_content119}}</li>
                           
                        </ul>

                        <h5>{{contentlist.terms_of_use_global_content120}}</h5>

                        <p><strong>{{contentlist.terms_of_use_global_content121}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content122}}</p>

                        <p>{{contentlist.terms_of_use_global_content123}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content124}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content125}}</p>

                        <p>{{contentlist.terms_of_use_global_content126}}</p>

                        <p>{{contentlist.terms_of_use_global_content127}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content128}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content129}}</p>

                        <h5>{{contentlist.terms_of_use_global_content130}}</h5>

                        <p>{{contentlist.terms_of_use_global_content131}} <a href="https://blog.capitalwallet.com/" class="secondarycolor">{{contentlist.terms_of_use_global_content132}}</a>{{contentlist.terms_of_use_global_content133}}</p>

                        <h5>{{contentlist.terms_of_use_global_content134}}</h5>

                        <p><strong>{{contentlist.terms_of_use_global_content135}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content136}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content137}}</router-link> {{contentlist.terms_of_use_global_content138}}</p>

                        <ul>
                           <li>{{contentlist.terms_of_use_global_content139}}</li>
                           <li>{{contentlist.terms_of_use_global_content140}}</li>
                           <li>{{contentlist.terms_of_use_global_content141}}</li>
                           <li>{{contentlist.terms_of_use_global_content142}}</li>
                           <li>{{contentlist.terms_of_use_global_content143}}</li>
                        </ul>

                        <p><strong>{{contentlist.terms_of_use_global_content144}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content145}}</p>

                        <ul>
                           <li>{{contentlist.terms_of_use_global_content146}}</li>
                           <li>{{contentlist.terms_of_use_global_content147}}</li>
                           <li>{{contentlist.terms_of_use_global_content148}}</li>
                           <li>{{contentlist.terms_of_use_global_content149}}</li>
                           <li>{{contentlist.terms_of_use_global_content150}}</li>
                           <li>{{contentlist.terms_of_use_global_content151}}</li>
                        </ul>


                        <p>{{contentlist.terms_of_use_global_content152}}</p>

                        <p>{{contentlist.terms_of_use_global_content153}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content154}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content155}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content156}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content157}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content158}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content159}}</p>

                        <ul>
                           <li>{{contentlist.terms_of_use_global_content160}}</li>
                           <li>{{contentlist.terms_of_use_global_content161}}</li>
                           <li>{{contentlist.terms_of_use_global_content162}}</li>
                           <li>{{contentlist.terms_of_use_global_content163}}</li>
                           <li>{{contentlist.terms_of_use_global_content164}}</li>
                        </ul>

                        <h5>{{contentlist.terms_of_use_global_content165}}</h5>

                        <p>{{contentlist.terms_of_use_global_content166}}</p>

                        <h5>{{contentlist.terms_of_use_global_content167}}</h5>

                        <p>{{contentlist.terms_of_use_global_content168}}</p>

                        <h5>{{contentlist.terms_of_use_global_content169}}</h5>

                        <p>{{contentlist.terms_of_use_global_content170}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content171}}</router-link> {{contentlist.terms_of_use_global_content172}}</p>

                        <h5>{{contentlist.terms_of_use_global_content173}}</h5>

                        <p>{{contentlist.terms_of_use_global_content174}}</p>

                        <p>{{contentlist.terms_of_use_global_content175}} <router-link :to="entityPath+'/privacy-policy'" class="secondarycolor">{{contentlist.terms_of_use_global_content176}}</router-link> {{contentlist.terms_of_use_global_content177}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content178}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content179}}</p>

                        <p>{{contentlist.terms_of_use_global_content180}}</p>

                        <p>{{contentlist.terms_of_use_global_content181}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content182}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content183}} <a href="https://www.allaboutcookies.org/" class="secondarycolor">{{contentlist.terms_of_use_global_content184}}</a></p>

                        <p><strong>{{contentlist.terms_of_use_global_content185}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content186}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content187}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content188}}</p>

                        <p>{{contentlist.terms_of_use_global_content189}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content190}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content191}}</p>

                        <p>{{contentlist.terms_of_use_global_content192}}</p>

                        <p>{{contentlist.terms_of_use_global_content193}}</p>

                        <p>{{contentlist.terms_of_use_global_content194}}</p>

                        <p>{{contentlist.terms_of_use_global_content195}}</p>

                        <p><strong>{{contentlist.terms_of_use_global_content196}}</strong></p>

                        <p>{{contentlist.terms_of_use_global_content197}}</p>

                        <p>{{contentlist.terms_of_use_global_content198}} <a href="https://www.allaboutcookies.org" class="secondarycolor">{{contentlist.terms_of_use_global_content199}}</a></p>

                        <p>{{contentlist.terms_of_use_global_content200}} <a href="mailto:support@capitalwallet.com" class="secondarycolor">{{contentlist.support_email_content}}</a></p>

                        <h5>{{contentlist.terms_of_use_global_content202}}</h5>

                        <p>{{contentlist.terms_of_use_global_content203}}</p>

                        <p>{{contentlist.terms_of_use_global_content204}}</p>


                        <p>{{contentlist.terms_of_use_global_content205}}</p>

                        <p>{{contentlist.terms_of_use_global_content206}}</p>

                        <p>{{contentlist.terms_of_use_global_content207}}</p>

                        <h5>{{contentlist.terms_of_use_global_content208}}</h5>

                        <p>{{contentlist.terms_of_use_global_content209}}</p>

                        <p>{{contentlist.terms_of_use_global_content210}}</p>

                        <p>{{contentlist.terms_of_use_global_content211}}</p>

                        <p>{{contentlist.terms_of_use_global_content112}}</p>

                        <p>{{contentlist.terms_of_use_global_content113}}</p>

                        <p>{{contentlist.terms_of_use_global_content114}}</p>

                        <p>{{contentlist.terms_of_use_global_content115}}</p>

                        <p>{{contentlist.terms_of_use_global_content116}}</p>
                        <p>{{contentlist.terms_of_use_global_content117}}</p>
                        <p>{{contentlist.terms_of_use_global_content118}} <router-link :to="entityPath+'/contact-us'" class="secondarycolor">{{contentlist.terms_of_use_global_content119}}</router-link></p>
                  </div>
               </div>
               
               
            </div>
         </section>
         
     </div>
</template>
<script>
  
  import {
    commonAllmixins
  } from '@/plugins/commonAll' //anamica
  export default ({
    mixins: [commonAllmixins], //anamica
    data() {
      return {
        tab: 1,
      };
    },
   
    created() {
      // console.log(this.contentlist)
    }
  });
</script>